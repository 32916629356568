import React, {useEffect, useRef, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import {ModalFooter, ModalHeader} from "../../Components/Modal";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateTimePicker from "react-datetime-picker";
import Select from "../../Components/Select";

const CompetitionModal = ({id, className='', callback, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputText, setInputText] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [inputStartAt, setInputStartAt] = useState(new Date());
    const [inputFinishAt, setInputFinishAt] = useState(new Date());
    const [inputContentType, setInputContentType] = useState('texts');
    const [inputMaximumSubmissions, setInputMaximumSubmissions] = useState(0);
    const photoRef = useRef(null);
    const [inputFile, setInputFile] = useState(null);
    const [inputImage, setInputImage] = useState(null);
    const [inputImageUrl, setInputImageUrl] = useState(null);
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
        const setData = (obj={}) => {
            const {
                title='',
                image=null,
                text='',
                start_at=new Date() / 1000,
                finish_at=new Date() / 1000,
                content_type='texts',
                maximum_submissions=0
            } = obj;
            setHeaderTitle(title);
            setInputTitle(title);
            setInputText(text);
            setInputStartAt(new Date(start_at * 1000));
            setInputFinishAt(new Date(finish_at * 1000));
            setInputContentType(content_type);
            setInputMaximumSubmissions(maximum_submissions);
            setFormErrors({});
        }
        if(id) {
            apiGet(`competitions/${id}`)
                .then(({data}) => {
                    const { competition, file } = data;
                    if(file !== false) {
                        setInputImageUrl(`${file.base_url}/${file.file_name}`);
                    }
                    setData(competition);
                });
        } else {
            setData();
        }
    }
    const save = btnCallback => {
        const callSave = (newImage=inputImage) => {
            const data = {
                title: inputTitle,
                image: newImage,
                text: inputText,
                start_at: inputStartAt / 1000,
                finish_at: inputFinishAt / 1000,
                content_type: inputContentType,
                maximum_submissions: inputMaximumSubmissions
            };
            const addr = id || '';
            apiPost(`competitions/${addr}`, data)
                .then(({data}) => {
                    toggle();
                    callback();
                })
                .catch(errors => {
                    btnCallback();
                    setFormErrors(errors);
                });
        }
        if(inputFile !== null) {
            const form = new FormData();
            form.append('file', inputFile);
            form.append('type', 'image');
            apiPost(`files`, form)
                .then(({data}) => {
                    const { created_id } = data;
                    callSave(created_id);
                })
        } else {
            callSave();
        }
    }
    const changePhoto = e => {
        e.preventDefault();
        photoRef.current.click();
    };
    const imageUrl = inputFile ? URL.createObjectURL(inputFile) : inputImageUrl;
    return <>
        <a href={window.location.href} onClick={open} className={className}>
            {children}
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true} size='lg'>
            <ModalHeader toggle={toggle}>
                {headerTitle || 'New'}
            </ModalHeader>
            <ModalBody>
                <div className='row mb-2'>
                    <div className='col-12'>
                        { imageUrl !== null && <img src={imageUrl} style={{maxHeight: '200px', objectFit: 'cover'}} onClick={changePhoto} /> }
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-12'>
                        <input
                            type='file'
                            ref={photoRef}
                            onChange={e => setInputFile(e.target.files[0])}
                            accept='image/png, image/jpeg'
                            // style={{'display': 'none'}}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Title'
                            value={inputTitle}
                            onChange={setInputTitle}
                            errors={['title', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label>Description</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={inputText}
                                config={{toolbar:{items:["heading","|","bold","italic","link","bulletedList","numberedList","|","indent","outdent","|","blockQuote","mediaEmbed","undo","redo"]},image:{toolbar:["imageStyle:full","imageStyle:side","|","imageTextAlternative"]},table:{contentToolbar:["tableColumn","tableRow","mergeTableCells"]},language:"en"}}
                                onChange={( event, editor) => {
                                    const data = editor.getData();
                                    setInputText(data);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <Input
                            type='number'
                            title='Maximum Submissions'
                            value={inputMaximumSubmissions}
                            onChange={e => setInputMaximumSubmissions(parseInt(e))}
                        />
                    </div>
                    <div className='col-6'>
                        <p className='mt-4'>{inputMaximumSubmissions === 0 && 'Unlimited'}</p>
                    </div>
                </div>
                <div className='row mt-1 mb-1'>
                    <div className='col-4'>
                        <div className='form-group'>
                            <label>Start at</label>
                            <DateTimePicker
                                className='form-control'
                                calendarClassName='datepicker'
                                onChange={e => setInputStartAt(e)}
                                value={inputStartAt}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='form-group'>
                            <label>Finish at</label>
                            <DateTimePicker
                                className='form-control'
                                calendarClassName='datepicker'
                                onChange={e => setInputFinishAt(e)}
                                value={inputFinishAt}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <Select
                            title='Type'
                            value={inputContentType}
                            onChange={setInputContentType}
                            options={[
                                {'value': 'texts', 'label': "Texts"},
                                {'value': 'images', 'label': "Images"},
                                {'value': 'videos', 'label': "Videos"},
                            ]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={save}
                toggle={toggle}
            />
        </Modal>
    </>
}


export default CompetitionModal;
