import AppStore from "../Stores/AppStore";
import axios from "axios";
import {load} from 'recaptcha-v3';
import replaceDiacritics from "./replaceDiacritics";

const getAction = url => {
    url = replaceDiacritics(url);
    url = (url.startsWith('/') ? url : `/${url}`)
        .replaceAll('/', '_')
        .replaceAll('-', '_');
    url = `action${url}`;
    return url;
}

const recaptcha = (url, config) => {
    return new Promise((resolve, _) => {
        if(String(process.env.REACT_APP_RECAPTCHA_ENABLED).toLowerCase() === 'true' && url === 'action_auth') {
            load(process.env.REACT_APP_RECAPTCHA_CLIENT, {
                autoHideBadge: true
            })
                .then(recaptcha => {
                    recaptcha.execute(url)
                        .then((token) => {
                            if(config === undefined || config === null) {
                                config = {};
                            }
                            if(config['headers'] === undefined) {
                                config['headers'] = {};
                            }
                            config['headers']['g-recaptcha-response'] = token;
                            config['headers']['g-recaptcha-action'] = url;
                            resolve(config);
                        })
                })
                .catch(() => AppStore.setError());
        } else {
            resolve(config);
        }
    });
}

const api = () => {
    const config = {
        baseURL: process.env.REACT_APP_API,
    };
    config['headers'] = {
        mode: 'no-cors',
        contentType: 'application/json',
        withCredentials: true,
        credentials: 'same-origin',
    };
    if (AppStore.token !== null) {
        config['headers']['Authorization'] = AppStore.token;
    }
    return axios.create(config);
}

const apiPost = (url, data, config) => {
    return new Promise((resolve, reject) => {
        recaptcha(getAction(url), config)
            .then(new_config => {
                api().post(url, data, new_config)
                    .then(response => {
                        const {data} = response;
                        const {message, errors} = data;
                        if (message === 'success') {
                            resolve(response);
                        } else if (message === 'error') {
                            reject(errors);
                        } else {
                            AppStore.setError();
                        }
                    })
                    .catch(() => AppStore.setError());
            })
    });
}

const apiDelete = (url, data, config) => {
    return new Promise((resolve, reject) => {
        recaptcha(getAction(url), config)
            .then(new_config => {
                api().delete(url, new_config)
                    .then(response => {
                        const {data} = response;
                        const {message, errors} = data;
                        if (message === 'success') {
                            resolve(response);
                        } else if (message === 'error') {
                            reject(errors);
                        } else {
                            AppStore.setError();
                        }
                    })
                    .catch(() => AppStore.setError());
            })
    });
}

const apiGet = (url, config) => {
    return new Promise((resolve, reject) => {
        recaptcha(getAction(url), config)
            .then(new_config => {
                api().get(url, new_config)
                    .then(response => {
                        const {data} = response;
                        const {message, errors} = data;
                        if (message === 'success') {
                            resolve(response);
                        } else if (message === 'error') {
                            reject(errors);
                        } else {
                            AppStore.setError();
                        }
                    })
                    .catch(() => AppStore.setError());
            })
    });
}


export {apiPost, apiGet, apiDelete}