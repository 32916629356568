import React, {useState} from 'react';
import Input from "./Input";
import Select from "./Select";
import {Tooltip} from "reactstrap";


const Table = ({columns=[], Row, entries=[], onSearch=null, header=false}) => {
    const [tooltipElement, toggleTooltip] = useState('');
    const [inputSearch, setInputSearch] = useState('');
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(10);
    const data = [];
    if(entries.length > 0) {
        const endPos = page * show > entries.length ? entries.length : page * show;
        for(let i = (page - 1) * show; i < (endPos > entries.length ? entries.length : endPos); i++) {
            data.push(<Row key={`table_body_${i}`} {...entries[i]} />);
        }
    }
    const getPagination = () => {
        const maxPages = Math.ceil(entries.length / show);
        const pages = [];
        let counter = 0;
        const btn = (newPage=1, className='') => {
            counter++;
            const buttons_props = {};
            let isDisabled = false;
            if(className.length || newPage === '...' || newPage === page)
                isDisabled = true;
            let className2 = '';
            if(newPage === page) {
                className2 += ' active';
            }
            return <li className={'paginate_button page-item ' + (className.startsWith('mdi') ? '' : className2)} key={counter} {...buttons_props} onClick={() => {
                if(!isDisabled) {
                    setPage(newPage);
                }
            }}>
                <a href='/' onClick={e => e.preventDefault()} className={'page-link'}>
                    {className.startsWith('mdi') ? <i className={className} /> : newPage}
                </a>
            </li>
        };
        pages.push(btn(page <= 1 ? 1 : page - 1, 'mdi mdi-chevron-left'));
        if(maxPages < 10) {
            for(let i = 0; i < maxPages; i++) {
                pages.push(btn(i + 1))
            }
        } else {
            pages.push(btn(1));
            if(page < 5) {
                pages.push(btn(2));
                pages.push(btn(3));
                pages.push(btn(4));
                pages.push(btn(5));
            } else {
                pages.push(btn("..."));
            }
            if(page > 4 && maxPages - 3 > page) {
                pages.push(btn(page - 1));
                pages.push(btn(page));
                pages.push(btn(page + 1));
            }
            if(maxPages - 4 < page) {
                pages.push(btn(maxPages - 4));
                pages.push(btn(maxPages - 3));
                pages.push(btn(maxPages - 2));
                pages.push(btn(maxPages - 1));
            } else {
                pages.push(btn("..."));
            }
            pages.push(btn(maxPages));
        }
        pages.push(btn(page >= maxPages ? maxPages : page + 1, 'mdi mdi-chevron-right'));
        if(pages.length < 4)
            return null;
        return <div className="dataTables_paginate paging_simple_numbers float-end">
            <ul className="pagination pagination-rounded">
                {pages}
            </ul>
        </div>;
    };
    const getColumns = () => {
        const column = (counter, value, index) => {
            if(typeof value === 'object' && !Array.isArray(value)) {
                let {
                    title='',
                    sort=false,
                    key='',
                    tooltip=null,
                    onClick=null,
                    w=null
                } = value;
                if(typeof sort === 'string') {
                    sort = true;
                    key = sort;
                }
                return <th
                    key={counter}
                    onClick={() => {
                        // this.sortBy(key);
                        if(onClick !== null)
                            onClick();
                    }}
                    id={'column-' + String(counter)}
                    className={(w ? `cw-${w}` : '') + (onClick ? ' sortable' : '')}
                >
                    {title}
                    { tooltip !== null && <Tooltip
                        isOpen={tooltipElement === counter}
                        target={'column-' + String(counter)}
                        toggle={() => toggleTooltip(counter === tooltipElement ? null : counter)}
                    >
                        {tooltip}
                    </Tooltip> }
                </th>
            } else if(typeof value === 'string') {
                return <th key={counter}>
                    {value}
                </th>;
            }
        };
        if(Array.isArray(columns)) {
            return columns.map((e, i) => column(i, e));
        } else {
            const temp = [];
            let counter = 0;
            for(let e in columns) {
                temp.push(column(counter, columns[e], e));
                counter++;
            }
            return temp;
        }
    }
    const inputOnSearch = value => {
        setInputSearch(value)
        if(onSearch !== null) onSearch(value);
    };
    return <div className='table-data'>
        <div className='row mt-3 mb-3"'>
            <div className="col-lg-12 col-xl-2">
                {entries.length >= 25 && <Select
                    value={show}
                    onChange={setShow}
                    options={[10, 25, 50, 100].filter((value) => value < entries.length).map((e) => ({'value': e, 'label': e}))}
                /> }
            </div>
            { Boolean(header) ? header : <div className={"col-xl-7"} /> }
            <div className="col-lg-12 col-xl-3">
                <Input
                    placeholder='Search...'
                    onChange={inputOnSearch}
                    value={inputSearch}
                    className=''
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>
                <div className='table-responsive'>
                    <table className='table table-striped table-centered mb-0'>
                        <thead>
                        <tr>{getColumns()}</tr>
                        </thead>
                        <tbody>
                        {data}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className='row mt-2 mb-3'>
            <div className='col-6'>
                <div className='info mt-1'>
                    Showing <span>{show * (page - 1) + 1}</span> to <span>{show * page > entries.length ? entries.length : show * page}</span> of <span>{entries.length}</span> entries
                </div>
            </div>
            <div className='col-6'>
                {getPagination()}
            </div>
        </div>
    </div>;
}


export default Table;
