import React from 'react';


const Loading = (props) => {
    return <>
        <div id="preloader">
            <div id="status">
                <div className="bouncing-loader">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    </>;
}


export default Loading;
