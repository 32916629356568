import React, {useEffect, useRef, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import {ModalFooter, ModalHeader} from "../../Components/Modal";

const CategoryModal = ({id, className='', callback, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputSlug, setInputSlug] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const photoRef = useRef(null);
    const [inputFile, setInputFile] = useState(null);
    const [inputImage, setInputImage] = useState(null);
    const [inputImageUrl, setInputImageUrl] = useState(null);
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
        const setData = (obj={}) => {
            const {
                title='',
                image=null,
                slug=''
            } = obj;
            setHeaderTitle(title);
            setInputTitle(title);
            setInputSlug(slug);
            setInputImage(image);
            setFormErrors({});
        }
        if(id) {
            apiGet(`categories/${id}`)
                .then(({data}) => {
                    const { category, file } = data;
                    if(file !== false) {
                        setInputImageUrl(`${file.base_url}/${file.file_name}`);
                    }
                    setData(category);
                });
        } else {
            setData();
        }
    }
    const save = btnCallback => {
        const callSave = (newImage=inputImage) => {
            const data = {
                title: inputTitle,
                image: newImage,
                slug: inputSlug
            };
            const addr = id || '';
            apiPost(`categories/${addr}`, data)
                .then(({data}) => {
                    toggle();
                    callback();
                })
                .catch(errors => {
                    btnCallback();
                    setFormErrors(errors);
                });
        }
        if(inputFile !== null) {
            const form = new FormData();
            form.append('file', inputFile);
            form.append('type', 'image');
            apiPost(`files`, form)
                .then(({data}) => {
                    const { created_id } = data;
                    callSave(created_id);
                })
        } else {
            callSave();
        }
    }
    const changePhoto = e => {
        e.preventDefault();
        photoRef.current.click();
    };
    const imageUrl = inputFile ? URL.createObjectURL(inputFile) : inputImageUrl;
    return <>
        <a href={window.location.href} onClick={open} className={className}>
            {children}
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                {headerTitle || 'New'}
            </ModalHeader>
            <ModalBody>
                <div className='row mb-2'>
                    <div className='col-12'>
                        { imageUrl !== null && <img src={imageUrl} style={{maxHeight: '200px', objectFit: 'cover'}} onClick={changePhoto} /> }
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-12'>
                        <input
                            type='file'
                            ref={photoRef}
                            onChange={e => setInputFile(e.target.files[0])}
                            accept='image/png, image/jpeg'
                            // style={{'display': 'none'}}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Title'
                            value={inputTitle}
                            onChange={setInputTitle}
                            errors={['title', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Slug'
                            value={inputSlug}
                            onChange={setInputSlug}
                            errors={['slug', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={save}
                toggle={toggle}
            />
        </Modal>
    </>
}


export default CategoryModal;
