import React, {useEffect, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet} from "../../Actions/Api";
import Table from "../../Components/Table";
import {prettySearch} from "../../Actions/replaceDiacritics";
import NotificationsModal from "./NotificationsModal";
import DeleteModal from "../../Components/DeleteModal";
import moment from "moment";

const Notifications = ({}) => {
    const [dataNotifications, setDataNotifications] = useState([]);
    const [isLoaded, setLoadingStatus] = useState(false);
    const [search, setSearch] = useState(false);
    const load = () => {
        setLoadingStatus(false);
        apiGet(`notifications`)
            .then(({ data }) => {
                const { notifications=[] } = data;
                setDataNotifications(notifications);
                setLoadingStatus(true);
            });
    };
    const tableRow = ({id, title, body, groups, run_at, total_rows, complete_rows, can_be_edit}) => {
        const newRunAt = moment.unix(run_at).format("MM/DD/YYYY");
        return <tr key={id}>
            <td>
                { can_be_edit ? <NotificationsModal
                    id={id}
                    callback={load}
                >{title}</NotificationsModal> : title }
            </td>
            <td>{body}</td>
            <td>{newRunAt}</td>
            <td>{total_rows === null ? 'Not started' : `${complete_rows} of ${total_rows}`}</td>
            <td>
                {can_be_edit ? <>
                    <NotificationsModal
                        id={id}
                        callback={load}
                        className='action-icon'
                    ><i className="mdi mdi-square-edit-outline" /></NotificationsModal>
                    <DeleteModal
                        title={title}
                        description={`Are you seriously want to delete notification "${title}"?`}
                        path={`notifications/${id}`}
                        callback={load}
                    />
                </> : 'Completed'}
            </td>
        </tr>;
    };
    useEffect(() => load(), []);
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {label: 'Notifications'}
    ];
    const resultData = dataNotifications.filter(({title}, i) => search.length === 0 || prettySearch(search, title));
    return <Container id='notifications' breadcrumbs={breadcrumbs} withCard={true} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <NotificationsModal
                    callback={load}
                    className='btn btn-primary'
                >New</NotificationsModal>
            </div>
        </div>
        <Table
            columns={[
                {title: 'Title'},
                {title: 'Body'},
                {title: 'Run at'},
                {title: 'Status'},
                {w: 2, title: 'Actions'}
            ]}
            entries={resultData}
            onSearch={setSearch}
            Row={tableRow}
        />
    </Container>;
}


export default Notifications;
