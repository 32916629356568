import React from 'react';
import Chart from "react-apexcharts";


const WidgetDepartament = ({data}) => {
    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            height: 60,
            // sparkline: {
            //     enabled: !0
            // }
        },
        plotOptions: {
            bar: {
                columnWidth: "60%"
            }
        },
        colors: ['#fff852','#fac712','#fd9444','#f85836','#ff0600','#c50d95','#733a91','#7733e6','#5270ff','#52a982','#35b729','#ccfd44'],
        series: data.dataset,
        labels: data.labels,
        legend: {
            show: false
        },
        tooltip: {
            // fixed: {
            //     enabled: !1
            // },
            // x: {
            //     show: !1
            // },
            // y: {
            //     title: {
            //         formatter: function() {
            //             return "";
            //         }
            //     }
            // },
            // marker: {
            //     show: !1
            // },
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                console.log(series, seriesIndex, dataPointIndex, w)
                // return data[dataPointIndex];
                return `${data.dataset[seriesIndex].label} - ${data.dataset[seriesIndex].data[dataPointIndex]}`
            }
        }
    };
    return <div className="card">
        <div className='card-header'>
            <h5>Počet aktivních uživatelů</h5>
        </div>
        <div className="card-body">
            <div className="row align-items-center">
                <div className="col-12">
                    <div className="text-right" style={{position: 'relative'}}>
                        <Chart
                            options={options}
                            series={options.series}
                            type={options.chart.type}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>;
};
export default WidgetDepartament;