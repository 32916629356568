import React from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {toJS} from "mobx";


const ContainerMenu = ({isShow, AppStore}) => {
    const menu = AppStore.menu;
    return <div className="topnav shadow-sm topnav-navbar-dark">
        <div className="container-fluid active">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                <div className={`collapse navbar-collapse active ${isShow ? 'show' : ''}`} id="topnav-menu-content">
                    <ul className="navbar-nav">
                        {toJS(menu).map((e, i) => {
                            const key =  `menu_${i}`;
                            const {
                                element_id=null,
                                to='',
                                label='',
                                icon=null,
                                side=null
                            } = e;
                            const isActive = element_id !== null && AppStore.activePage === element_id ? 'active' : '';
                            if(side === null || side.length === 0) {
                                return <li key={key} className={`nav-item`}>
                                    <Link to={to} className={`nav-link ${isActive}`}>
                                        {icon !== null && <i className={`${icon} me-1`}/> } {label}
                                    </Link>
                                </li>;
                            }
                            return <li key={key} className={`nav-item dropdown`}>
                                <a className={`nav-link dropdown-toggle arrow-none ${isActive}`} href={window.location.href} onClick={e => e.preventDefault()} id="topnav-dashboards" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {icon !== null && <i className={`${icon} me-1`}/> } {label} <div className="arrow-down"/>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="topnav-dashboards">
                                    {side.map((e, i) => {
                                        const {
                                            to='',
                                            label
                                        } = e;
                                        const newKey = `${key}_${i}`;
                                        return <Link key={newKey} to={to} className='dropdown-item'>{label}</Link>
                                    })}
                                </div>
                            </li>;
                        })}
                    </ul>
                </div>
            </nav>
        </div>
    </div>;
}


export default inject('AppStore')(observer(ContainerMenu));
