
const titleCase = str => {
    str = str.toLowerCase().split(' ');
    let final = [ ];
    for(let  word of str){
        final.push(word.charAt(0).toUpperCase()+ word.slice(1));
    }
    return final.join(' ');
}

const getUserName = (user={}, options={}) => {
    let {
        first_name='',
        last_name='',
        nickname=null
    } = user;
    const {
        show_nickname=true,
        abbr=false
    } = options;
    first_name = titleCase(first_name.trim());
    last_name = titleCase(last_name.trim());
    if(abbr && last_name.length > 0) {
        last_name = `${last_name[0]}.`;
    }
    nickname = nickname !== null && show_nickname ? `"${titleCase(nickname.trim())}"` : '';
    return `${first_name} ${nickname} ${last_name}`.trim();
};

export {getUserName, titleCase}