import React, {useEffect, useState} from 'react';
import Container from "../Components/Container";
import Widget from "../Components/Widget";
import WidgetDepartament from "../Components/WidgetDepartament";
import {apiGet} from "../Actions/Api";

const Dashboard = ({...props}) => {
    const [isLoaded, setLoadingStatus] = useState(false);
    const [widgetDataArticles, setWidgetDataArticles] = useState([0, 0, 0, 0, 0, 0]);
    const [widgetDataNotifications, setWidgetDataNotifications] = useState([0, 0, 0, 0, 0, 0]);
    const [widgetDataFcm, setWidgetDataFcm] = useState([0, 0, 0, 0, 0, 0]);
    const [widgetDataVisit, setWidgetDataVisit] = useState([0, 0, 0, 0, 0, 0]);
    const [widgetDataDep, setWidgetDataDep] = useState({'labels': [], 'dataset': []});
    useEffect(() => load(), []);
    // useEffect(() => load(), [props]);
    const load = () => {
        setLoadingStatus(false);
        apiGet(`/auth/dashboard`)
            .then(({data}) => {
                const { articles, notifications, fcm, visit, deportment } = data.data;
                setWidgetDataArticles(articles);
                setWidgetDataNotifications(notifications);
                setWidgetDataFcm(fcm);
                setWidgetDataVisit(visit);
                setWidgetDataDep(deportment);
                setLoadingStatus(true);
            });
    };
    const breadcrumbs = [
        {label: 'Dashboard'}
    ];
    return <Container id='dashboard' title='Dashboard' breadcrumbs={breadcrumbs} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-6'>
                <Widget
                    chartKey={`articles`}
                    title={'Počet uživatelů'}
                    type={'bar'}
                    data={widgetDataArticles}
                />
            </div>
            <div className='col-6'>
                <Widget
                    chartKey={`notifications`}
                    title={'Počet odeslaných notifikací'}
                    type={'bar'}
                    data={widgetDataNotifications}
                    hidePercentage={true}
                />
            </div>
            <div className='col-6'>
                <Widget
                    chartKey={`visit`}
                    title={'Počet aktivních uživatelů'}
                    type={'bar'}
                    data={widgetDataVisit}
                    hidePercentage={true}
                />
            </div>
            <div className='col-6'>
                <Widget
                    chartKey={`fcm`}
                    title={'Počet uživatelů se zapnutými notifikacemi'}
                    type={'bar'}
                    data={widgetDataFcm}
                    hidePercentage={true}
                />
            </div>
            <div className='col-12'>
                <WidgetDepartament data={widgetDataDep} />
            </div>
        </div>
    </Container>;
}


export default Dashboard;
