import React, {useEffect, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet, apiPost} from "../../Actions/Api";
import Table from "../../Components/Table";
import {prettySearch} from "../../Actions/replaceDiacritics";
import CompetitionModal from "./CompetitionModal";
import DeleteModal from "../../Components/DeleteModal";
import Position from "../../Components/Position";
import moment from "moment/moment";
import {Link} from "react-router-dom";

const Competitions = ({}) => {
    const [dataCompetitions, setDataCompetitions] = useState([]);
    const [isLoaded, setLoadingStatus] = useState(false);
    const [dataFiles, setDataFiles] = useState(new Map());
    const [search, setSearch] = useState(false);
    const load = () => {
        setLoadingStatus(false);
        apiGet(`competitions`)
            .then(({ data }) => {
                const { competitions=[], files=[] } = data;
                const tmp = new Map();
                for(const file of files) {
                    tmp.set(file.id, `${file.base_url}/${file.file_name}`);
                }
                setDataFiles(tmp);
                setDataCompetitions(competitions);
                setLoadingStatus(true);
            });
    };
    const tableRow = ({id, title, image, text, start_at, finish_at, content_type, maximum_submissions, finished_submissions}) => {
        const newStartAt = moment.unix(start_at).format("MM/DD/YYYY");
        const newFinishAt = moment.unix(finish_at).format("MM/DD/YYYY");
        const imageUrl = dataFiles.get(image) || null;
        return <tr key={id}>
            <td>
                {imageUrl !== null && <img src={imageUrl} className="rounded me-3" height={48} /> }
            </td>
            <td>
                <Link to={`/competitions/${id}`}>{title}</Link>
            </td>
            <td>{newStartAt}</td>
            <td>{newFinishAt}</td>
            <td>{finished_submissions}</td>
            <td>
                <CompetitionModal
                    id={id}
                    callback={load}
                    className='action-icon'
                ><i className="mdi mdi-square-edit-outline" /></CompetitionModal>
                <DeleteModal
                    title={title}
                    description={`Are you seriously want to delete competition "${title}"?`}
                    path={`competitions/${id}`}
                    callback={load}
                />
            </td>
        </tr>;
    };
    useEffect(() => load(), []);
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {label: 'Competitions'}
    ];
    const resultData = dataCompetitions.filter(({title}, i) => search.length === 0 || prettySearch(search, title));
    return <Container id='competitions' breadcrumbs={breadcrumbs} withCard={true} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <CompetitionModal
                    callback={load}
                    className='btn btn-primary'
                >New</CompetitionModal>
            </div>
        </div>
        <Table
            columns={[
                {w: 3, title: 'Preview'},
                {title: 'Title'},
                {title: 'Start at'},
                {title: 'Finish at'},
                {title: 'Submissions'},
                {w: 2, title: 'Actions'}
            ]}
            entries={resultData}
            onSearch={setSearch}
            Row={tableRow}
        />
    </Container>;
}


export default Competitions;
