import React, {useEffect} from "react";
import {titleCase} from "../Actions/UserName";
import {Link} from "react-router-dom";

const ContainerBreadcrumbs = (props) => {
    const {
        title=false,
        breadcrumbs=false
    } = props;
    useEffect(() => {
        const newTitlePage = getTitlePage();
        if(newTitlePage !== null) {
            document.title = newTitlePage;
        }
    }, []);
    const getTitlePage = () => {
        if(title !== false) {
            return titleCase(title);
        } else if(breadcrumbs !== false && breadcrumbs.length > 0) {
            return titleCase(breadcrumbs[breadcrumbs.length - 1].label);
        }
        return null;
    }
    const getBreadcrumbs = () => {
        return <div className="page-title-right">
            <ol className="breadcrumb m-0">
                {breadcrumbs.map(({to=null, label}, i) => {
                    const key = `breadcrumb_${i}`;
                    const newLabel = titleCase(label);
                    if(to !== null) {
                        return <li key={key} className='breadcrumb-item active'><Link to={to}>{newLabel}</Link></li>
                    }
                    return <li key={key} className='breadcrumb-item active'>{newLabel}</li>;
                })}
            </ol>
        </div>;
    }
    if(title === false && breadcrumbs === false)
        return null;
    return <div className="row">
        <div className="col-12">
            <div className="page-title-box">
                { breadcrumbs !== false && getBreadcrumbs() }
                <h4 className="page-title">{getTitlePage()}</h4>
            </div>
        </div>
    </div>
}

export default ContainerBreadcrumbs;