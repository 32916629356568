import React, {useEffect, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet, apiPost} from "../../Actions/Api";
import Table from "../../Components/Table";
import {prettySearch} from "../../Actions/replaceDiacritics";
import MenuModal from "./MenuModal";
import DeleteModal from "../../Components/DeleteModal";
import {inject, observer} from "mobx-react";
import Position from "../../Components/Position";

const Menu = ({AppStore}) => {
    const [dataMenu, setDataMenu] = useState([]);
    const [optionsRoles, setOptionsRoles] = useState([]);
    const [isLoaded, setLoadingStatus] = useState(false);
    const [search, setSearch] = useState(false);
    const load = () => {
        setLoadingStatus(false);
        apiGet(`menu`)
            .then(({ data }) => {
                const { menu=[] } = data;
                setDataMenu(menu);
                setLoadingStatus(true);
                AppStore.setSession();
            });
    };
    const tableRow = ({id, label, permissions, element_id, position}) => {
        return <tr key={id}>
            <td>
                <MenuModal
                    id={id}
                    callback={load}
                    optionsRoles={optionsRoles}
                >{label}</MenuModal>
            </td>
            <td>{element_id}</td>
            <td>{permissions.map((e, i) => {
                const role = optionsRoles.find(({value}) => value === e);
                if(!role) return null;
                return <span key={`${id}_${role.value}`} className='badge badge-outline-primary me-1'>{role.label}</span>
            })}</td>
            <td className="table-action">
                <MenuModal
                    id={id}
                    callback={load}
                    className='action-icon'
                    optionsRoles={optionsRoles}
                ><i className="mdi mdi-square-edit-outline" /></MenuModal>
                <Position
                    path={`menu/${id}/position`}
                    position={position}
                    callback={load}
                />
                <DeleteModal
                    title={label}
                    description={`Are you seriously want to delete menu "${label}"?`}
                    path={`menu/${id}`}
                    callback={load}
                />
            </td>
        </tr>;
    };
    useEffect(() => {
        load();
        apiGet(`roles`)
            .then(({ data }) => {
                const { roles=[] } = data;
                setOptionsRoles(roles.map(({id, title}) => ({value: id, label: title})));
            });
    }, []);
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {label: 'Menu'}
    ];
    const resultData = dataMenu.filter(({label}, i) => search.length === 0 || prettySearch(search, label));
    return <Container id='menu' breadcrumbs={breadcrumbs} withCard={true} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <MenuModal
                    callback={load}
                    className='btn btn-primary'
                    optionsRoles={optionsRoles}
                >New</MenuModal>
            </div>
        </div>
        <Table
            columns={[
                {title: 'Title'},
                {title: 'Element Id'},
                {title: 'Roles'},
                {w: 2, title: 'Actions'}
            ]}
            entries={resultData}
            onSearch={setSearch}
            Row={tableRow}
        />
    </Container>;
}


export default inject('AppStore')(observer(Menu));
