
let storageKey = '';
if(process.env.REACT_APP_STORAGE_KEY !== undefined) {
    storageKey = `${process.env.REACT_APP_STORAGE_KEY}_`;
}

const getKey = key => {
    return `${storageKey}${key}`;
}

const getItem = (key, defaultValue=null) => {
    return window.localStorage.getItem(getKey(key)) || defaultValue;
}

const setItem = (key, value) => {
    window.localStorage.setItem(getKey(key), value);
    return true;
}

const removeItem = (key) => {
    window.localStorage.removeItem(getKey(key));
    return true;
}

export default {getItem, setItem, removeItem}