import React, {useEffect, useRef, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import {ModalFooter, ModalHeader} from "../../Components/Modal";
import DateTimePicker from "react-datetime-picker";
import Select from "../../Components/Select";

const NotificationsModal = ({id, className='', callback, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [optionsGroups, setOptionsGroups] = useState([]);
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputBody, setInputBody] = useState('');
    const [selectGroups, setSelectGroups] = useState([]);
    const [inputRunAt, setInputRunAt] = useState(new Date());
    const [formErrors, setFormErrors] = useState({});
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
        const setData = (obj={}) => {
            const {
                title='',
                body='',
                groups=[],
                run_at=new Date() / 1000
            } = obj;
            setHeaderTitle(title);
            setInputTitle(title);
            setInputBody(body);
            setSelectGroups(groups);
            setInputRunAt(new Date(run_at * 1000));
            setFormErrors({});
        }
        apiGet(`/groups`)
            .then(({data}) => {
                const { groups } = data;
                const options = groups
                    .filter((e) => e.id !== id)
                    .map(({id, name}) => ({value: id, label: name}));
                setOptionsGroups(options);
                if(id) {
                    apiGet(`notifications/${id}`)
                        .then(({data}) => {
                            const { notifications } = data;
                            setData(notifications);
                        });
                } else {
                    setData();
                }
            });
    }
    const save = btnCallback => {
        const data = {
            title: inputTitle,
            body: inputBody,
            groups: selectGroups,
            run_at: inputRunAt / 1000
        };
        const addr = id || '';
        apiPost(`notifications/${addr}`, data)
            .then(({data}) => {
                toggle();
                callback();
            })
            .catch(errors => {
                btnCallback();
                setFormErrors(errors);
            });
    }
    return <>
        <a href={window.location.href} onClick={open} className={className}>
            {children}
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                {headerTitle || 'New'}
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Title'
                            value={inputTitle}
                            onChange={setInputTitle}
                            errors={['title', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Body'
                            type='textarea'
                            value={inputBody}
                            onChange={setInputBody}
                            errors={['body', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Select
                            title='Dostupnost'
                            value={selectGroups}
                            onChange={setSelectGroups}
                            isMulti={true}
                            options={optionsGroups}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label>Published at</label>
                            <DateTimePicker
                                className='form-control'
                                calendarClassName='datepicker'
                                onChange={e => setInputRunAt(e)}
                                value={inputRunAt}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={save}
                toggle={toggle}
                disabled={inputTitle.length === 0 || inputBody.length === 0}
            />
        </Modal>
    </>
}


export default NotificationsModal;
