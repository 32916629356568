import React, {useState} from "react";

const ModalHeader = ({children, toggle}) => {
    const onClick = e => {
        e.preventDefault();
        toggle();
    }
    return <div className='modal-header'>
        <div className='modal-title'>{children}</div>
        <button className='btn-close' onClick={onClick} />
    </div>
}

const ModalFooter = ({title='Save', onSave, toggle=null, disabled=false}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const caller = () => {
        setIsDisabled(true);
        onSave(() => setIsDisabled(false))
    };
    return <div className='modal-footer'>
        {toggle !== null && <button type="button" className="btn btn-light" onClick={toggle}>Close</button> }
        <button type="button" className="btn btn-primary" onClick={caller} disabled={disabled || isDisabled}>{title}</button>
    </div>;
}

export { ModalHeader, ModalFooter };