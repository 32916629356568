import React, {useEffect, useState} from 'react';
import {MakeId} from "../Actions/MakeId";


const Input = (props) => {
    const {
        title=null,
        value='',
        onChange=null,
        placeholder=null,
        type='text',
        errors=null,
        className='mt-1 mb-1',
        rows=4
    } = props;
    const [inputId, setInputId] = useState('');
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        setInputId(MakeId(32));
        setInputValue(value);
    }, []);
    useEffect(() => {
        setInputValue(value);
    }, [props]);
    const inputOnChange = e => {
        const value = type !== 'checkbox' ? e.target.value : !inputValue;
        if(onChange !== null) {
            onChange(value);
        }
        setInputValue(value);
        // if(errors !== null) {
        //     if(errors[1][errors[0]] !== undefined && inputValue !== value) {
        //         delete errors[1][errors[0]];
        //         errors[2](errors[1]);
        //     }
        // }
    };
    const error = (errors !== null && errors[1][errors[0]]&& errors[1][errors[0]].length > 0) ? errors[1][errors[0]][0] : null;
    if(type === 'checkbox') {
        return <div className="form-check">
            <input type="checkbox" className={`form-check-input ${error !== null ? 'is-invalid': ''}`} id={inputId} onChange={inputOnChange} checked={inputValue} value={inputValue} />
            <label className="form-check-label" htmlFor={inputId}>{title}</label>
        </div>;
    }
    return <div className={className}>
        { title !== null && <label
            htmlFor={inputId}
            className="form-label"
        >{title}</label> }
        {type === 'textarea' ? <textarea
            className={`form-control ${error !== null ? 'is-invalid': ''}`}
            rows={rows}
            id={inputId}
            placeholder={placeholder}
            value={inputValue}
            onChange={inputOnChange}
        /> : <input
            className={`form-control ${error !== null ? 'is-invalid': ''}`}
            type={type}
            id={inputId}
            placeholder={placeholder}
            value={inputValue}
            onChange={inputOnChange}
        />}
        {error !== null && <div className='invalid-feedback'>{error}</div>}
    </div>;
}


export default Input;
