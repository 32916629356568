import React, {useEffect, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet} from "../../Actions/Api";
import Table from "../../Components/Table";
import {prettySearch} from "../../Actions/replaceDiacritics";
import UsersModal from "./UsersModal";
import {getUserName} from "../../Actions/UserName";

const Users = ({}) => {
    const [dataUsers, setDataUsers] = useState([]);
    const [dataRoles, setDataRoles] = useState(new Map());
    const [isLoaded, setLoadingStatus] = useState(false);
    const [search, setSearch] = useState(false);
    const load = () => {
        setLoadingStatus(false);
        apiGet(`roles`)
            .then(({ data }) => {
                const { roles=[] } = data;
                const rolesMap = new Map();
                for(const role of roles) {
                    rolesMap.set(role.id, role.title);
                }
                setDataRoles(rolesMap);
                apiGet(`users`)
                    .then(({ data }) => {
                        const { users=[] } = data;
                        setDataUsers(users);
                        setLoadingStatus(true);
                    });
            });
    };
    const tableRow = ({id, first_name, last_name, email, role_id}) => {
        const userName = getUserName({first_name, last_name});
        let role_title = null;
        if(role_id !== null) {
            const role = dataRoles.get(role_id);
            if(role) {
                role_title = <span key={`${id}_${role_id}`} className='badge badge-outline-primary me-1'>{role}</span>;
            }
        }
        return <tr key={id}>
            <td>
                <UsersModal
                    id={id}
                    callback={load}
                >{userName}</UsersModal>
            </td>
            <td>{role_title}</td>
            <td>{email.toLowerCase()}</td>
            <td>
                <UsersModal
                    id={id}
                    callback={load}
                    className='action-icon'
                ><i className="mdi mdi-square-edit-outline" /></UsersModal>
            </td>
        </tr>;
    };
    useEffect(() => load(), []);
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {label: 'Users'}
    ];
    const resultData = dataUsers.filter(({first_name, last_name, email}, i) => search.length === 0 || prettySearch(search, first_name, last_name, email));
    return <Container id='users' breadcrumbs={breadcrumbs} withCard={true} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <UsersModal
                    callback={load}
                    className='btn btn-primary'
                >New</UsersModal>
            </div>
        </div>
        <Table
            columns={[
                {title: 'Name'},
                {title: 'Role'},
                {title: 'E-Mail'},
                {w: 2, title: 'Actions'}
            ]}
            entries={resultData}
            onSearch={setSearch}
            Row={tableRow}
        />
    </Container>;
}


export default Users;
