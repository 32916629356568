import { observable, action, toJS } from 'mobx';
import Storage from '../Actions/Storage';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {apiGet, apiPost} from "../Actions/Api";

console.warn = () => {};

export class AppStore {

    @observable activePage = null;
    @observable loading = true;
    @observable isError = false;
    @observable isSigned = false;
    @observable axios = null;
    @observable deviceId = null;
    @observable user = null;
    @observable menu = [];
    @observable files = new Map();
    @observable token = null;

    constructor() {
        const deviceId = Storage.getItem('device_id', null);
        if(deviceId === null) {
            const fpPromise = FingerprintJS.load()
            ;(async () => {
                const fp = await fpPromise
                const result = await fp.get()
                this.deviceId = result.visitorId;
                Storage.setItem('device_id', result.visitorId);
            })();
        } else {
            this.deviceId = deviceId;
        }
    }

    @action setSession(argToken, argTokenExpire) {
        const token = argToken || Storage.getItem('token', null);
        const token_expire = argTokenExpire || Storage.getItem('token_expire_at', null);
        this.loading = true;
        if(token === null || token_expire === null || ((new Date()) / 1000) > parseInt(token_expire)) {
            Storage.removeItem('token');
            Storage.removeItem('token_expire_at');
            setTimeout(() => this.loading = false, 1000);
        } else {
            this.token = token;
            apiGet(`/auth/session`)
                .then(({data}) => {
                    const { user, menu } = data;
                    this.user = user;
                    this.menu = menu;
                    this.isSigned = true;
                    setTimeout(() => this.loading = false, 700);
                })
                .catch(({session}) => {
                    this.loading = false;
                    console.info(session);
                    if(session[0] === 'Invalid session') {
                        this.isError = true;
                    }
                    Storage.removeItem('token');
                    Storage.removeItem('token_expire_at');
                });
        }
    }

    @action setError(error) {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.isError = true;
        }, 1000);
    }

    @action login(data) {
        const {
            token,
            expire_at
        } = data;
        const dateExpireAt = parseInt(((new Date()) / 1000) + expire_at);
        Storage.setItem('token', token);
        Storage.setItem('token_expire_at', dateExpireAt);
        this.setSession(token, dateExpireAt);
    }

    @action logout() {
        const after = () => {
            Storage.removeItem('token');
            Storage.removeItem('token_expire_at');
            this.isSigned = false;
            this.token = null;
            console.info('Session destroyed');
        };
        if(this.isSigned) {
            apiPost(`/auth/session/destroy`)
                .then(() => after())
                .catch(() => after());
        } else {
            after();
        }
    }
}

export default new AppStore();
