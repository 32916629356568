import React from 'react';
import Sel from 'react-select';

class Select extends React.Component {
    render() {
        const  {
            title=null,
            isMulti=false,
            options=[],
            onChange,
            isBoolean=false,
            unshiftOption,
            disabled=false,
            className='',
            formatGroupLabel=undefined,
            isGroup=false,
            isSearchable=true,
            onInputChange=(()=>{})
        } = this.props;
        const optionsTemp = [...options];
        if(unshiftOption) {
            optionsTemp.unshift(unshiftOption);
        }
        let { value } = this.props;
        if(isBoolean) {
            value = Boolean(value).toString();
        }
        let selectedValue = [];
        let defaultValue = null;
        if(isGroup) {
            for(let group of (optionsTemp ? optionsTemp : [])) {
                for(let option of group.options) {
                    if(defaultValue === null && !isMulti) {
                        defaultValue = option;
                    }
                    if(isMulti) {
                        if(Array.isArray(value) === true && value.includes(option.value)) {
                            selectedValue.push(option);
                        }
                    } else {
                        if(option.value === value) {
                            selectedValue = option;
                        }
                    }
                }
            }
        } else {
            for(let option of (optionsTemp ? optionsTemp : [])) {
                if(defaultValue === null && !isMulti) {
                    defaultValue = option;
                }
                if(isMulti) {
                    if(Array.isArray(value) === true && value.includes(option.value)) {
                        selectedValue.push(option);
                    }
                } else {
                    if(option.value === value) {
                        selectedValue = option;
                    }
                }
            }
        }
        return <div className={'form-group ' + className}>
            { title }
            <Sel
                onInputChange={e => (onInputChange !== null && onInputChange(e))}
                isSearchable={isSearchable}
                formatGroupLabel={formatGroupLabel}
                isDisabled={disabled}
                isMulti={isMulti}
                options={optionsTemp}
                closeMenuOnSelect={true}
                defaultValue={ isMulti ? [defaultValue] : defaultValue}
                value={((isMulti && selectedValue.length) || Object.keys(selectedValue).length) ? selectedValue : (isMulti ? [defaultValue] : defaultValue)}
                classNamePrefix="select"
                onChange={(e) => {
                    let value;
                    if(isMulti) {
                        value = [];
                        for(let v of (e !== null ? e : [])) {
                            value.push(v.value);
                        }
                    } else {
                        value = e.value;
                    }
                    if(!isMulti && isBoolean) {
                        value = value === 'true';
                    }
                    return onChange ? onChange(value) : null;
                }}
            />
        </div>;
    }
}

export default Select;