import React from 'react';
import Maintenance from '../Assets/images/maintenance.svg';


const ErrorPage = (props) => {
    return <div className="mt-5 mb-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10">
                    <div className="text-center">
                        <img src={Maintenance} height="140" alt="File not found Image" />
                            <h3 className="mt-4">Site is Under Maintenance</h3>
                            <p className="text-muted">We're making the system more awesome. We'll be back shortly.</p>
                            <div className="row mt-5">
                                <div className="col-md-4">
                                    <div className="text-center mt-3 ps-1 pe-1">
                                        <i className="dripicons-jewel text-primary maintenance-icon mb-2" />
                                        <h5 className="text-uppercase">Why is the Site Down?</h5>
                                        <p className="text-muted">There are many variations of passages of Lorem Ipsum
                                            available, but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center mt-3 ps-1 pe-1">
                                        <i className="dripicons-clock text-primary maintenance-icon mb-2" />
                                        <h5 className="text-uppercase">What is the Downtime?</h5>
                                        <p className="text-muted">Contrary to popular belief, Lorem Ipsum is not simply
                                            random text. It has roots in a piece of classical but the majority.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center mt-3 ps-1 pe-1">
                                        <i className="dripicons-question text-primary maintenance-icon mb-2" />
                                        <h5 className="text-uppercase">Do you need Support?</h5>
                                        <p className="text-muted">If you are going to use a passage of Lorem Ipsum, you
                                            need to be sure there isn't anything embar..
                                            <a href="mailto:#" className="text-muted fw-bold">no-reply@domain.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}


export default ErrorPage;
