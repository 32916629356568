import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import App from './app';
import AppStore from "./Stores/AppStore";
import Actions from "./Stores/AppStore";

const stores = {
    AppStore,
    Actions
};

ReactDOM.render(<Provider {...stores}>
    <App />
</Provider>, document.getElementById('root'));

serviceWorker.unregister();
