import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import Loading from "./Loading";
import Image1 from "../Assets/images/logo-light.png";
import ContainerUser from "./ContainerUser";
import ContainerMenu from "./ContainerMenu";
import ContainerNonAuth from "./ContainerNonAuth";
import {Link} from "react-router-dom";
import ContainerBreadcrumbs from "./ContainerBreadcrumbs";


const Container = (props) => {
    const {
        AppStore,
        id=null,
        auth=false,
        breadcrumbs=false,
        title=false,
        withCard=false,
        children,
        isLoaded=true
    } = props;
    const [menuIsShow, toggleMenu] = useState(false);
    useEffect(() => {
        AppStore.activePage = id;
    }, []);
    if(AppStore.loading || !isLoaded) {
        return <Loading />;
    }
    if(auth) {
        return <ContainerNonAuth {...props} />;
    } else {
        return <div className="wrapper" key='container'>
            <div className="content-page">
                <div className="content">
                    <div className="navbar-custom topnav-navbar">
                        <div className="container-fluid">
                            <Link to='/' className='topnav-logo'>
                                <span className="topnav-logo-lg">
                                    <img src={Image1} alt="" height="35"/>
                                </span>
                                <span className="topnav-logo-sm">
                                    <img src={Image1} alt="" height="30"/>
                                </span>
                            </Link>
                            <a className="navbar-toggle collapsed" data-bs-toggle="collapse"
                               data-bs-target="#topnav-menu-content" aria-expanded="false" onClick={e => {
                               e.preventDefault();
                               toggleMenu(!menuIsShow);
                               }
                            }>
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                            <ContainerUser />
                        </div>
                    </div>
                    <ContainerMenu isShow={menuIsShow} />
                    <div className="container-fluid mb-5">
                        <ContainerBreadcrumbs {...{breadcrumbs, title}} />
                        {withCard ? <div className='row'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div> : children}
                    </div>
                </div>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                {new Date().getFullYear()} © App
                            </div>
                            <div className="col-md-6">
                                {/*<div className="text-md-end footer-links d-none d-md-block">*/}
                                {/*    <a href="#">About</a>*/}
                                {/*    <a href="#">Support</a>*/}
                                {/*    <a href="#">Contact Us</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>;
    }
}


export default inject('AppStore')(observer(Container));
