import React, {useEffect, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet, apiPost} from "../../Actions/Api";
import Table from "../../Components/Table";
import {prettySearch} from "../../Actions/replaceDiacritics";
import RoleModal from "./RoleModal";
import DeleteModal from "../../Components/DeleteModal";

const Roles = ({}) => {
    const [dataRoles, setDataRoles] = useState([]);
    const [isLoaded, setLoadingStatus] = useState(false);
    const [search, setSearch] = useState(false);
    const load = () => {
        setLoadingStatus(false);
        apiGet(`roles`)
            .then(({ data }) => {
                const { roles=[] } = data;
                setDataRoles(roles);
                setLoadingStatus(true);
            });
    };
    const tableRow = ({id, title}) => {
        return <tr key={id}>
            <td>
                <RoleModal
                    id={id}
                    callback={load}
                >{title}</RoleModal>
            </td>
            <td>
                <RoleModal
                    id={id}
                    callback={load}
                    className='action-icon'
                ><i className="mdi mdi-square-edit-outline" /></RoleModal>
                <DeleteModal
                    title={title}
                    description={`Are you seriously want to delete role "${title}"?`}
                    path={`roles/${id}`}
                    callback={load}
                />
            </td>
        </tr>;
    };
    useEffect(() => load(), []);
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {label: 'Roles'}
    ];
    const resultData = dataRoles.filter(({title}, i) => search.length === 0 || prettySearch(search, title));
    return <Container id='roles' breadcrumbs={breadcrumbs} withCard={true} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <RoleModal
                    callback={load}
                    className='btn btn-primary'
                >New</RoleModal>
            </div>
        </div>
        <Table
            columns={[
                {title: 'Title'},
                {w: 2, title: 'Actions'}
            ]}
            entries={resultData}
            onSearch={setSearch}
            Row={tableRow}
        />
    </Container>;
}


export default Roles;
