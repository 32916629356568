import React, {useEffect, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import {ModalFooter, ModalHeader} from "../../Components/Modal";

const RoleModal = ({id, className='', callback, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputSlug, setInputSlug] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
        const setData = (obj={}) => {
            const {
                title='',
                slug=''
            } = obj;
            setHeaderTitle(title);
            setInputTitle(title);
            setInputSlug(slug);
            setFormErrors({});
        }
        if(id) {
            apiGet(`roles/${id}`)
                .then(({data}) => {
                    const { role } = data;
                    setData(role);
                });
        } else {
            setData();
        }
    }
    const save = btnCallback => {
        const data = {
            title: inputTitle,
            slug: inputSlug
        };
        const addr = id || '';
        apiPost(`roles/${addr}`, data)
            .then(({data}) => {
                toggle();
                callback();
            })
            .catch(errors => {
                btnCallback();
                setFormErrors(errors);
            });
    }
    return <>
        <a href={window.location.href} onClick={open} className={className}>
            {children}
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                {headerTitle || 'New'}
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Title'
                            value={inputTitle}
                            onChange={setInputTitle}
                            errors={['title', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Slug'
                            value={inputSlug}
                            onChange={setInputSlug}
                            errors={['slug', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={save}
                toggle={toggle}
            />
        </Modal>
    </>
}


export default RoleModal;
