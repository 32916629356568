import React, {useEffect, useRef, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Redirect} from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import {Modal, ModalBody} from "reactstrap";
import {ModalFooter, ModalHeader} from "../../Components/Modal";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import uuid from 'react-uuid';


const style = {
    objectFit: 'cover',
    width: '100px',
    height: '100px',
};


// fake data generator
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`,
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

const ArticleGallery = ({gallery=[], onChange}) => {
    const photoRef = useRef(null);
    const [filesList, setFilesList] = useState({});
    const [uploadedFilesList, setUploadedFilesList] = useState(gallery || [])
    const openFileDialog = e => {
        e.preventDefault();
        photoRef.current.click();
    }
    const uploadFile = e => {
        e.preventDefault();
        const filesCount = e.target.files.length;
        if (filesCount === 0)
            return
        let newFilesList = {};
        for(const file of e.target.files) {
            const fileUUID = uuid();
            newFilesList[fileUUID] = file;
        }
        newFilesList = {...filesList, ...newFilesList};
        let newUploadedFiles = [...uploadedFilesList];
        setFilesList(newFilesList);
        const upload = () => {
            const obj = Object.entries(newFilesList).find(e => e);
            if(!obj)
                return;
            const [id, file] = obj;
            const form = new FormData();
            form.append('file', file);
            form.append('type', 'image');
            apiPost(`files`, form)
                .then(({data}) => {
                    newUploadedFiles.push(`${data.file.base_url}/${data.file.file_name}`);
                    delete newFilesList[id];
                    setFilesList({...newFilesList});
                    setUploadedFilesList(newUploadedFiles);
                    onChange(newUploadedFiles);
                    upload();
                })
                .catch(() => {
                    delete newFilesList[id];
                    setFilesList({...newFilesList});
                    midUploadedFilesList(newUploadedFiles);
                    upload();
                })
        }
        upload();
    }
    const midUploadedFilesList = filesList => {
        setUploadedFilesList(filesList);
        if(onChange) onChange(filesList);
    };
    const onDrag = result => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            uploadedFilesList,
            result.source.index,
            result.destination.index
        );
        midUploadedFilesList(items);
    }
    const isDisabled = Object.entries(filesList).reduce((accumulator, [key, value]) => accumulator + (typeof value === "object" ? 1 : 0), 0);
    return <>
        <input
            type='file'
            ref={photoRef}
            onChange={uploadFile}
            accept='image/png, image/jpeg'
            style={{'display': 'none'}}
            multiple={true}
        />
        <h5>Gallery</h5>
        <DragDropContext onDragEnd={onDrag}>
            <Droppable droppableId="droppable" direction="horizontal" onDragEnd={onDrag}>
                {(provided, snapshot) =>
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {uploadedFilesList.map((e, i) => {
                            return <Draggable droppableId="droppable" draggableId={`gallery_${i}`} index={i} key={`gallery_${i}`}>
                                {(provided, snapshot) =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <Image src={e} onDelete={() => midUploadedFilesList(uploadedFilesList.filter((_, i2) => i2 !== i))} />
                                        {/*<img src={e} style={style} alt='gallery' />*/}
                                    </div>}
                            </Draggable>
                        })}
                        {Object.entries(filesList).map(([key, value], i) => {
                            const imageUrl = URL.createObjectURL(value);
                            i = i+uploadedFilesList.length;
                            return <Draggable droppableId="droppable" draggableId={`gallery_${i}`} index={i} key={`gallery_${i}`} isDragDisabled={true}>
                                {(provided, snapshot) =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <img src={imageUrl} style={{...style, opacity: 0.5}} alt='gallery' />
                                    </div>}
                            </Draggable>
                        })}
                        <button className='btn btn-primary btn-sm font-12 rounded-3 pb-1' onClick={openFileDialog}
                                disabled={isDisabled}>
                            +
                        </button>
                    </div>}
            </Droppable>
        </DragDropContext>
        <div className='article-files' style={{display: 'flex', flexDirection: 'column'}}>
        </div>
    </>;
}

const Image = ({src, onDelete}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return <>
        <img src={src} alt={'Gallery'} style={style} onClick={toggle} />
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                Image
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-12'>
                        Are you seriously want to delete image?
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={() => {
                    onDelete();
                    toggle();
                }}
                title={'Yes'}
                toggle={toggle}
            />
        </Modal>
    </>;
}

export default ArticleGallery;
