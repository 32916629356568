import React from 'react';
import {apiPost} from "../Actions/Api";


const Position = (props) => {
    const {
        callback,
        path='',
        position=0
    } = props;
    return <>
        <a
            href={window.location.href}
            onClick={(btn) => {
                btn.preventDefault();
                apiPost(path, {position: position + 1})
                    .then(() => callback());
            }}
            className='btn-none action-icon'
        >
            <i className='mdi mdi-arrow-down-bold-circle-outline'/>
        </a>
        <a
            href={window.location.href}
            onClick={(btn) => {
                btn.preventDefault();
                apiPost(path, {position: position - 1})
                    .then(() => callback());
            }}
            className='action-icon'
        >
            <i className='mdi mdi-arrow-up-bold-circle-outline'/>
        </a>
    </>;
}

export default Position;