import React, {useEffect, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet, apiPost} from "../../Actions/Api";
import Table from "../../Components/Table";
import {prettySearch} from "../../Actions/replaceDiacritics";
import CategoryModal from "./CategoryModal";
import DeleteModal from "../../Components/DeleteModal";
import Position from "../../Components/Position";

const Categories = ({}) => {
    const [dataCategories, setDataCategories] = useState([]);
    const [isLoaded, setLoadingStatus] = useState(false);
    const [dataFiles, setDataFiles] = useState(new Map());
    const [search, setSearch] = useState(false);
    const load = () => {
        setLoadingStatus(false);
        apiGet(`categories`)
            .then(({ data }) => {
                const { categories=[], files=[] } = data;
                const tmp = new Map();
                for(const file of files) {
                    tmp.set(file.id, `${file.base_url}/${file.file_name}`);
                }
                setDataFiles(tmp);
                setDataCategories(categories);
                setLoadingStatus(true);
            });
    };
    const tableRow = ({id, title, image, position}) => {
        const imageUrl = dataFiles.get(image) || null;
        return <tr key={id}>
            <td>
                {imageUrl !== null && <img src={imageUrl} className="rounded me-3" height={48} /> }
            </td>
            <td>
                <CategoryModal
                    id={id}
                    callback={load}
                >{title}</CategoryModal>
            </td>
            <td>
                <CategoryModal
                    id={id}
                    callback={load}
                    className='action-icon'
                ><i className="mdi mdi-square-edit-outline" /></CategoryModal>
                <Position
                    path={`categories/${id}/position`}
                    position={position}
                    callback={load}
                />
                <DeleteModal
                    title={title}
                    description={`Are you seriously want to delete category "${title}"?`}
                    path={`categories/${id}`}
                    callback={load}
                />
            </td>
        </tr>;
    };
    useEffect(() => load(), []);
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {label: 'Categories'}
    ];
    const resultData = dataCategories.filter(({title}, i) => search.length === 0 || prettySearch(search, title));
    return <Container id='categories' breadcrumbs={breadcrumbs} withCard={true} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <CategoryModal
                    callback={load}
                    className='btn btn-primary'
                >New</CategoryModal>
            </div>
        </div>
        <Table
            columns={[
                {w: 3, title: 'Preview'},
                {title: 'Title'},
                {w: 2, title: 'Actions'}
            ]}
            entries={resultData}
            onSearch={setSearch}
            Row={tableRow}
        />
    </Container>;
}


export default Categories;
