import React, {useEffect, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import {ModalFooter, ModalHeader} from "../../Components/Modal";
import Select from "../../Components/Select";

const MenuModal = ({id, className='', optionsRoles=[], callback, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputElementId, setInputElementId] = useState('');
    const [inputTo, setInputTo] = useState('');
    const [inputLabel, setInputLabel] = useState('');
    const [inputIcon, setInputIcon] = useState('');
    const [selectSide, setSelectSide] = useState([]);
    const [selectPermissions, setSelectPermissions] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
        const setData = (obj={}) => {
            const {
                element_id='',
                to='',
                label='',
                icon='',
                side=[],
                permissions=[]
            } = obj;
            setHeaderTitle(label);
            setInputElementId(element_id);
            setInputTo(to);
            setInputLabel(label);
            setInputIcon(icon);
            setSelectSide(side);
            setSelectPermissions(permissions);
            setFormErrors({});
        }
        if(id) {
            apiGet(`menu/${id}`)
                .then(({data}) => {
                    const { menu } = data;
                    setData(menu);
                });
        } else {
            setData();
        }
    }
    const save = btnCallback => {
        const data = {
            element_id: inputElementId,
            to: inputTo,
            label: inputLabel,
            icon: inputIcon,
            side: selectSide,
            permissions: selectPermissions
        };
        const addr = id || '';
        apiPost(`menu/${addr}`, data)
            .then(({data}) => {
                toggle();
                callback();
            })
            .catch(errors => {
                btnCallback();
                setFormErrors(errors);
            });
    }
    const addOption = e => {
        e.preventDefault();
        setSelectSide([...selectSide, {to: '', title: ''}]);
    }
    const removeOption = (e, optionNum) => {
        e.preventDefault();
        setSelectSide(selectSide.filter((_, i) => i !== optionNum));
    }
    const changeValue = (optionNum, value) => {
        setSelectSide(selectSide.map((e, i) => optionNum === i ? value : e));
    }
    return <>
        <a href={window.location.href} onClick={open} className={className}>
            {children}
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                {headerTitle || 'New'}
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Label'
                            value={inputLabel}
                            onChange={setInputLabel}
                            errors={['label', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Url to'
                            value={inputTo}
                            onChange={setInputTo}
                            errors={['to', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <Input
                            title='Element ID'
                            value={inputElementId}
                            onChange={setInputElementId}
                            errors={['element_id', formErrors, setFormErrors]}
                        />
                    </div>
                    <div className='col-6'>
                        <Input
                            title='Icon'
                            value={inputIcon}
                            onChange={setInputIcon}
                            errors={['icon', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <h5>
                            <span className='mt-2'>Options</span>
                            <button className='btn btn-outline-primary float-end' onClick={addOption}>Add</button>
                        </h5>
                    </div>
                </div>
                {selectSide.map(({to, label}, i) => {
                    return <div className='row' key={`option_${i}`}>
                        <div className='col-4'>
                            <Input
                                title='Option to'
                                value={to}
                                onChange={e => changeValue(i, {to: e, label})}
                            />
                        </div>
                        <div className='col-6'>
                            <Input
                                title='Option label'
                                value={label}
                                onChange={e => changeValue(i, {to, label: e})}
                            />
                        </div>
                        <div className='col-2'>
                            <button className='btn btn-outline-warning float-end mt-3' onClick={e => removeOption(e, i)}>-</button>
                        </div>
                    </div>;
                })}
                <div className='row'>
                    <div className='col-12'>
                        <Select
                            title='Articles'
                            isMulti={true}
                            options={optionsRoles}
                            value={selectPermissions}
                            onChange={setSelectPermissions}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={save}
                toggle={toggle}
            />
        </Modal>
    </>
}


export default MenuModal;
