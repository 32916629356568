import React, {useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import {apiDelete} from "../Actions/Api";
import {ModalFooter, ModalHeader} from "./Modal";

const DeleteModal = ({title, description, path, callback}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
    }
    const save = () => {
        apiDelete(path)
            .then(_ => {
                toggle();
                callback();
            });
    }
    return <>
        <a href={window.location.href} onClick={open} className='action-icon'>
            <i className="mdi mdi-delete" />
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                {title}
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-12'>
                        {description}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={save}
                toggle={toggle}
            />
        </Modal>
    </>
}


export default DeleteModal;
