import React, {useEffect, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import {ModalFooter, ModalHeader} from "../../Components/Modal";
import {getUserName} from "../../Actions/UserName";
import Select from "../../Components/Select";

const UserPasswordModal = ({callback}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputPassword, setInputPassword] = useState('');
    const [inputRePassword, setInputRePassword] = useState('');
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
    }
    const save = btnCallback => {
        btnCallback();
        toggle();
        callback(inputPassword);
    };
    return <>
        <a href={window.location.href} onClick={open} className='btn btn-outline-primary'>
            Set password
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                Set password
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Password'
                            value={inputPassword}
                            onChange={setInputPassword}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Repeat password'
                            value={inputRePassword}
                            onChange={setInputRePassword}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                disabled={inputPassword !== inputRePassword || inputPassword.length < 6}
                onSave={save}
                toggle={toggle}
            />
        </Modal>
    </>
}


const UsersModal = ({id, className='', callback, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [optionsRoles, setOptionsRoles] = useState([]);
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputFirstName, setInputFirstName] = useState('');
    const [inputLastName, setInputLastName] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [inputRoleId, setInputRoleId] = useState('');
    const [inputPassword, setInputPassword] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const toggle = () => setIsOpen(!isOpen);
    const open = e => {
        e.preventDefault();
        toggle();
        const setData = (obj={}) => {
            const {
                first_name='',
                last_name='',
                email='',
                role_id=''
            } = obj;
            setHeaderTitle(getUserName({first_name, last_name}));
            setInputFirstName(first_name);
            setInputLastName(last_name);
            setInputEmail(email);
            setInputRoleId(role_id);
            setFormErrors({});
        }
        apiGet(`roles`)
            .then(({data}) => {
                const { roles=[] } = data;
                setOptionsRoles(roles.map(({id, title}) => ({value: id, label: title})));
                if(id) {
                    apiGet(`users/${id}`)
                        .then(({data}) => {
                            const { user } = data;
                            setData(user);
                        });
                } else {
                    setData();
                }
            });
    }
    const save = btnCallback => {
        const data = {
            first_name: inputFirstName,
            last_name: inputLastName,
            email: inputEmail,
            role_id: inputRoleId
        };
        const addr = id || '';
        apiPost(`users/${addr}`, data)
            .then(({data}) => {
                if(inputPassword !== null) {
                    const user_id = id || data.created_id;
                    apiPost(`users/${user_id}/password`, {password: inputPassword})
                        .then(({data}) => {
                            toggle();
                            callback();
                        });
                } else {
                    toggle();
                    callback();
                }
            })
            .catch(errors => {
                btnCallback();
                setFormErrors(errors);
            });
    }
    if(isOpen && optionsRoles.length > 0 && inputRoleId === '') {
        setInputRoleId(optionsRoles[optionsRoles.length - 1].value);
    }
    return <>
        <a href={window.location.href} onClick={open} className={className}>
            {children}
        </a>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                {headerTitle || 'New'}
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-6'>
                        <Input
                            title='First name'
                            value={inputFirstName}
                            onChange={setInputFirstName}
                            errors={['first_name', formErrors, setFormErrors]}
                        />
                    </div>
                    <div className='col-6'>
                        <Input
                            title='Last name'
                            value={inputLastName}
                            onChange={setInputLastName}
                            errors={['last_name', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='E-Mail'
                            value={inputEmail}
                            onChange={setInputEmail}
                            errors={['email', formErrors, setFormErrors]}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Select
                            title='Role'
                            value={inputRoleId}
                            onChange={setInputRoleId}
                            options={optionsRoles}
                        />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <UserPasswordModal
                            callback={setInputPassword}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter
                onSave={save}
                toggle={toggle}
            />
        </Modal>
    </>
}


export default UsersModal;
