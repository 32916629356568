import React, {useEffect, useState} from 'react';
import svg_logout from "../Assets/images/startman.svg";
import {inject, observer} from "mobx-react";
import {Redirect} from "react-router-dom";


const PageLogout = ({AppStore}) => {
    const [responseRedirect, setResponseRedirect] = useState(null);
    useEffect(() => {
        AppStore.logout();
        setTimeout(() => setResponseRedirect('/'),  5000);
    }, []);
    if(responseRedirect !== null) {
        return <Redirect to={responseRedirect} />;
    }
    return <div className="mt-5 mb-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10">
                    <div className="text-center">
                        <h4 className="text-dark-50 text-center mt-0 font-weight-bold">See You Again!</h4>
                        <p className="text-muted mb-4">You are now successfully sign out.</p>
                        <img id='Layer_1' className='m-auto d-block' src={svg_logout} style={{width: '35%'}} />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}


export default inject('AppStore')(observer(PageLogout));
