import React, {useState} from 'react';
import {inject, observer} from "mobx-react";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import Avatar from "../Assets/images/NoPhoto.jpeg";
import {getUserName} from "../Actions/UserName";
import {Link} from "react-router-dom";


const ContainerUser = ({AppStore, auth=false, children}) => {
    const [isOpen, toggle] = useState(false);
    const { user } = AppStore;
    const userName = getUserName(user, {show_nickname: false, abbr: true});
    return <ul className="list-unstyled topbar-menu float-end mb-0">
        <Dropdown tag={'li'}  className={'notification-list'} isOpen={isOpen} toggle={() => toggle(!isOpen)}>
            <DropdownToggle tag={'a'} className={'nav-link nav-user arrow-none mr-0'}>
                <span className="account-user-avatar">
                    <img src={Avatar} alt="user-image" className="rounded-circle"/>
                </span>
                <span>
                    <span className="account-user-name">{userName}</span>
                    <span className="account-position">{user.role.title}</span>
                </span>
            </DropdownToggle>
            <DropdownMenu className={'dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown'}>
                <div className=" dropdown-header noti-title">
                    <h6 className="text-overflow m-0">Welcome !</h6>
                </div>
                <Link to='/logout' className="dropdown-item notify-item">
                    <i className="mdi mdi-logout me-1"/>
                    <span>Logout</span>
                </Link>
            </DropdownMenu>
        </Dropdown>
    </ul>;
}


export default inject('AppStore')(observer(ContainerUser));
