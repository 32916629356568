import React from "react";
import {Route, Switch} from "react-router-dom";
import PageArticles from './Articles';
import PageArticleModify from './ArticlesModify';

const Articles = () => {
    return <Switch>
        <Route path='/articles' exact component={PageArticles} />
        <Route path='/articles/:id/new' component={PageArticleModify} />
        <Route path='/articles/:id' component={PageArticleModify} />
    </Switch>;
}

export default Articles;