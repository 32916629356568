import React, {useEffect, useRef, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet, apiPost} from "../../Actions/Api";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Redirect} from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import {Modal, ModalBody} from "reactstrap";
import {ModalFooter, ModalHeader} from "../../Components/Modal";
import ArticleGallery from "./ArticleGallery";


const Tags = ({tags, onChange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dataTags, setDataTags] = useState([]);
    const [inputTitle, setInputTitle] = useState('');
    useEffect(() => {
        apiGet(`tags`)
            .then(({ data }) => {
                setDataTags(data.data);
            })
    }, []);
    const toggle = () => setIsOpen(!isOpen);

    const add = () => {
        onChange([...tags, inputTitle]);
        toggle();
    };
    const remove = elementId => onChange(tags.filter((_, i) => i !== elementId));
    const newOptions = dataTags.map(e => ({value: e, label: e}));
    return <>
        {tags.map((e, i) => {
            const key = `tags_${i}`;
            return <button className='btn p-0 me-1' key={key} onClick={_ => remove(i)}>
                <span className='badge badge-outline-primary font-12'>{e}</span>
            </button>;
        })}
        <button className='btn btn-primary btn-sm font-12 rounded-3 pb-1' onClick={toggle}>
            +
        </button>
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                Tags
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-12'>
                        <Input
                            title='Custom name'
                            value={inputTitle}
                            onChange={setInputTitle}
                        />
                    </div>
                </div>
                {(dataTags.includes(inputTitle) || inputTitle.length === 0) && <>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            or
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <Select
                                title='Select an existing tag'
                                value={inputTitle}
                                onChange={setInputTitle}
                                options={newOptions}
                            />
                        </div>
                    </div>
                </>}
            </ModalBody>
            <ModalFooter
                onSave={add}
                toggle={toggle}
                disabled={inputTitle.length === 0 || tags.includes(inputTitle)}
            />
        </Modal>
    </>;
}


const ArticleModify = (props) => {
    const { match } = props
    const { params, url } = match;
    const { id } = params;
    const [preview, togglePreview] = useState(false);
    const [responseRedirect, setResponseRedirect] = useState(null);
    const [optionsGroups, setOptionsGroups] = useState([]);
    const [optionsCategories, setOptionsCategories] = useState([]);
    const [listTags, setListTags] = useState([]);
    const [optionsRecommendedArticles, setOptionsRecommendedArticles] = useState([]);
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputImage, setInputImage] = useState(null);
    const [inputImageUrl, setInputImageUrl] = useState(null);
    const [selectCategoryId, setSelectCategoryId] = useState('');
    const [selectType, setSelectType] = useState('article');
    const [selectIsPublished, setSelectIsPublished] = useState(false);
    const [selectIsPublic, setSelectIsPublic] = useState(false);
    const [selectRecommendedArticles, setSelectRecommendedArticles] = useState([]);
    const [inputContent, setInputContent] = useState('');
    const [inputPreview, setInputPreview] = useState('');
    const [inputPublishedAt, setInputPublishedAt] = useState(new Date());
    const [selectGroups, setSelectGroups] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [isLoaded, setLoadingStatus] = useState(false);
    const [btnIsDisabled, setDisabled] = useState(false);
    const photoRef = useRef(null);
    const [inputFile, setInputFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const setData = (obj={}) => {
        const {
            title='',
            image=null,
            category_id='',
            type='article',
            is_published=false,
            recommended_articles=[],
            content='',
            preview='',
            published_at=(new Date() / 1000),
            tags=[],
            groups=[],
            is_public=false,
            gallery=[]
        } = obj;
        setHeaderTitle(title);
        setInputTitle(title);
        setInputImage(image);
        if(category_id !== '') {
            setSelectCategoryId(category_id);
        }
        setSelectType(type);
        setSelectIsPublished(is_published);
        setSelectIsPublic(is_public);
        setInputPublishedAt(new Date(published_at * 1000));
        setSelectRecommendedArticles(recommended_articles);
        setInputContent(content);
        setInputPreview(preview);
        setListTags(tags);
        setSelectGroups(groups);
        setInputFile(null);
        setFormErrors({});
        setGallery(gallery);
    }
    const load = () => {
        setLoadingStatus(false);
        if(!url.endsWith('/new')) {
            apiGet(`/articles/${id}`)
                .then(({data}) => {
                    const {
                        article,
                        file
                    } = data;
                    if(file !== false) {
                        setInputImageUrl(`${file.base_url}/${file.file_name}`);
                    }
                    setData(article);
                    setLoadingStatus(true);
                });
        } else {
            setData();
            setLoadingStatus(true);
        }
        apiGet(`/categories`)
            .then(({data}) => {
                const { categories } = data;
                const options = categories.map(({id, title, slug}) => ({value: id, label: `${title} (${slug})`, slug: slug}));
                if(selectCategoryId === '' && url.endsWith('/new') ) {
                    if(id === 'new') {
                        setSelectCategoryId(options[0].value);
                    } else {
                        setSelectCategoryId(options.find((e) => e.slug === id).value);
                    }
                }
                setOptionsCategories(options);
            });
        apiGet(`/articles`)
            .then(({data}) => {
                const { articles } = data;
                const options = articles
                    .filter((e) => e.id !== id)
                    .map(({id, title}) => ({value: id, label: title}));
                setOptionsRecommendedArticles(options);
            });
        apiGet(`/groups`)
            .then(({data}) => {
                const { groups } = data;
                const options = groups
                    .filter((e) => e.id !== id)
                    .map(({id, name}) => ({value: id, label: name}));
                setOptionsGroups(options);
            });
    }
    useEffect(() => load(), []);
    const callSave = (newImage=inputImage) => {
        const data = {
            title: inputTitle,
            image: newImage,
            category_id: selectCategoryId,
            type: selectType,
            is_published: selectIsPublished,
            recommended_articles: selectRecommendedArticles,
            content: inputContent,
            preview: inputPreview,
            published_at: inputPublishedAt / 1000,
            tags: listTags,
            groups: selectGroups,
            is_public: selectIsPublic,
            gallery: gallery
        };
        const addr = url.endsWith('/new') ? '' : id;
        apiPost(`articles/${addr}`, data)
            .then(({data}) => {
                const { category } = data;
                setDisabled(false);
                if(category) {
                    const { slug } = category;
                    setResponseRedirect(`/${slug}`);
                }
                // load();
            })
            .catch(errors => {
                setFormErrors(errors);
            });
    }
    const save = e => {
        e.preventDefault();
        setDisabled(true);
        if(inputFile !== null) {
            const form = new FormData();
            form.append('file', inputFile);
            form.append('type', 'image');
            apiPost(`files`, form)
                .then(({data}) => {
                    const { created_id } = data;
                    callSave(created_id);
                })
        } else {
            callSave();
        }
    }
    const changePhoto = e => {
        e.preventDefault();
        photoRef.current.click();
    };
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {to: '/articles', label: 'Articles'},
        {label: headerTitle}
    ];
    if(responseRedirect !== null) {
        return <Redirect to={responseRedirect} />;
    }
    const imageUrl = inputFile ? URL.createObjectURL(inputFile) : inputImageUrl;
    return <Container id='articles' title={headerTitle} breadcrumbs={breadcrumbs} isLoaded={isLoaded}>
        <div className='row'>
            <div className={preview ? 'col-8' : 'col-12'}>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                { imageUrl !== null && <img src={imageUrl} style={{maxHeight: '200px', objectFit: 'cover'}} onClick={changePhoto} /> }
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                <input
                                    type='file'
                                    ref={photoRef}
                                    onChange={e => setInputFile(e.target.files[0])}
                                    accept='image/png, image/jpeg'
                                    // style={{'display': 'none'}}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                <ArticleGallery gallery={gallery} onChange={setGallery} />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                <Input
                                    title='Title'
                                    value={inputTitle}
                                    onChange={setInputTitle}
                                    errors={['title', formErrors, setFormErrors]}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label>Preview</label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={inputPreview}
                                        config={{toolbar:{items:["heading","|","bold","italic","link","bulletedList","numberedList","|","indent","outdent","|","blockQuote","mediaEmbed","undo","redo"]},image:{toolbar:["imageStyle:full","imageStyle:side","|","imageTextAlternative"]},table:{contentToolbar:["tableColumn","tableRow","mergeTableCells"]},language:"en"}}
                                        onChange={( event, editor) => {
                                            const data = editor.getData();
                                            setInputPreview(data);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label>Content</label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        config={{toolbar:{items:["heading","|","bold","italic","link","bulletedList","numberedList","|","indent","outdent","|","blockQuote","mediaEmbed","undo","redo"]},image:{toolbar:["imageStyle:full","imageStyle:side","|","imageTextAlternative"]},table:{contentToolbar:["tableColumn","tableRow","mergeTableCells"]},language:"en"}}
                                        data={inputContent}
                                        onChange={( event, editor) => {
                                            const data = editor.getData();
                                            setInputContent(data);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-3'>
                                <Select
                                    title='Category'
                                    value={selectCategoryId}
                                    onChange={setSelectCategoryId}
                                    options={optionsCategories}
                                />
                            </div>
                            {/*<div className='col-4'>*/}
                            {/*    <Select*/}
                            {/*        title='Type'*/}
                            {/*        value={selectType}*/}
                            {/*        onChange={setSelectType}*/}
                            {/*        options={[*/}
                            {/*            {value: 'article', label: 'Article'}*/}
                            {/*        ]}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className='col-3'>
                                <Select
                                    title='Available for Public'
                                    value={selectIsPublic}
                                    onChange={setSelectIsPublic}
                                    isBoolean={true}
                                    options={[
                                        {'value': 'true', 'label': 'Yes'},
                                        {'value': 'false', 'label': 'No'},
                                    ]}
                                />
                            </div>
                            <div className='col-3'>
                                <Select
                                    title='Is Published'
                                    value={selectIsPublished}
                                    onChange={setSelectIsPublished}
                                    isBoolean={true}
                                    options={[
                                        {'value': 'true', 'label': 'Yes'},
                                        {'value': 'false', 'label': 'No'},
                                    ]}
                                />
                            </div>
                            <div className='col-3'>
                                <div className='form-group'>
                                    <label>Published at</label>
                                    <DateTimePicker
                                        className='form-control'
                                        calendarClassName='datepicker'
                                        onChange={e => setInputPublishedAt(e)}
                                        value={inputPublishedAt}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                <Select
                                    title='Recommended articles'
                                    value={selectRecommendedArticles}
                                    onChange={setSelectRecommendedArticles}
                                    isMulti={true}
                                    options={optionsRecommendedArticles}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-12'>
                                <Select
                                    title='Dostupnost'
                                    value={selectGroups}
                                    onChange={setSelectGroups}
                                    isMulti={true}
                                    options={optionsGroups}
                                />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12 mb-1'>
                                Tags
                            </div>
                            <div className='col-12'>
                                <Tags tags={listTags} onChange={setListTags} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='btn btn-outline-secondary' onClick={() => togglePreview(!preview)}>
                                    Show preview
                                </button>
                                <button className='btn btn-primary float-end' onClick={save} disabled={btnIsDisabled}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {preview && <div className='col-4'>
                <div className='card'>
                    {imageUrl !== null && <img className="card-img-top" src={imageUrl} alt="Card image cap" style={{maxHeight: '200px', objectFit: 'cover'}} /> }
                    <div className='card-header' style={{fontWeight: 'bold'}}>
                        {inputTitle}
                    </div>
                    <div className='card-body ck-editor' dangerouslySetInnerHTML={{__html: inputPreview}} />
                    <div className='card-body ck-editor border-top-0 pt-0' dangerouslySetInnerHTML={{__html: inputContent}} />
                </div>
            </div> }
        </div>
    </Container>;
}


export default ArticleModify;
