import React from 'react';
import {inject, observer} from "mobx-react";


const ContainerNonAuth = ({children}) => {
    return <div className="authentication-bg pb-0">
        <div className="auth-fluid">
            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}


export default inject('AppStore')(observer(ContainerNonAuth));
