import React from 'react';
import Chart from "react-apexcharts";


const Widget = ({chartKey, title, type='bar', data=[], data2=false, hidePercentage=false, bottomText=false, months=false, monthsLabels=[]}) => {
    let newType;
    if(['bar', 'line'].includes(type))
        newType = type;
    else
        newType = 'bar';
    const isRevers = title.startsWith('Zero');
    const options = {
        chart: {
            type: newType,
            stacked: true,
            height: 60,
            sparkline: {
                enabled: !0
            }
        },
        plotOptions: {
            bar: {
                columnWidth: "60%"
            }
        },
        colors: ["#ee4411", '#ffbd87'],
        series: data2 !== false ? [{data: data}, {data: data2}] : [{data: data}],
        labels: data,
        xaxis: {
            crosshairs: {
                width: 1
            }
        },
        tooltip: {
            fixed: {
                enabled: !1
            },
            x: {
                show: !1
            },
            y: {
                title: {
                    formatter: function() {
                        return "";
                    }
                }
            },
            marker: {
                show: !1
            },
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                if(data2 !== false)
                    return `${data[dataPointIndex]} vs ${data2[dataPointIndex]}`;
                if(months !== false) {
                    return `${monthsLabels[months[dataPointIndex-1]]}: ${data[dataPointIndex]}`;
                }
                return data[dataPointIndex];
            }
        }
    };
    const formatNumber = (number, isPercent=false) => {
        const isPositive = number >= 0;
        if(number < 0)
            number = -number;
        if(isPercent && number >= 100)
            number = 100;
        if(isPercent && number < 1) {
            number = 0
        }
        if(isPercent && isNaN(number))
            number = 0;
        const value = Math.round(parseFloat(number) * 100) / 100;
        return {isPositive, value: isPercent ? Math.floor(value) : value};
    };
    const newTitleValue = formatNumber(data2 !== false ? data[data.length - 1] + data2[data2.length - 1] : data[data.length - 1]);
    const newPercentValue = formatNumber(100 / newTitleValue.value * (newTitleValue.value - data[data.length - 2]), true);
    return <div className="card">
        <div className="card-body">
            <div className="row align-items-center">
                <div className="col-6">
                    <h5 className="text-muted font-weight-normal mt-0 text-truncate" title={title}>{title}</h5>
                    <h3 className="my-2 py-1">{newTitleValue.value}</h3>
                    { !hidePercentage && <p className="mb-0 text-muted">
                        {newPercentValue.value !== 0 ?
                            (isRevers ? <span className={`text-${newPercentValue.isPositive ? 'danger' : 'success'} mr-2`}>
                            <i className={`mdi mdi-arrow-${newPercentValue.isPositive ? 'down' : 'up'}-bold`}/> {newPercentValue.value}%</span>
                                :<span className={`text-${newPercentValue.isPositive ? 'success' : 'danger'} mr-2`}>
                            <i className={`mdi mdi-arrow-${newPercentValue.isPositive ? 'up' : 'down'}-bold`}/> {newPercentValue.value}%</span>)
                            :
                            <span className={` mr-2`} />
                        }
                    </p> }
                    { bottomText !== false && <p className="mb-0 text-muted">
                        {bottomText}
                    </p> }
                </div>
                <div className="col-6">
                    <div className="text-right" style={{position: 'relative'}}>
                        <Chart
                            key={chartKey}
                            options={options}
                            series={options.series}
                            type={options.chart.type}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>;
};
export default Widget;