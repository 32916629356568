import React from "react";
import {Route, Switch} from "react-router-dom";
import PageCompetition from './Competitions';
import PageCompetitionReview from './CompetitionReview';

const Competitions = () => {
    return <Switch>
        <Route path='/competitions' exact component={PageCompetition} />
        <Route path='/competitions/:id' component={PageCompetitionReview} />
    </Switch>;
}

export default Competitions;