import React, {useEffect} from 'react';
import './Assets/scss/app.scss';
import {inject, observer} from "mobx-react";
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Dashboard from "./Pages/Dashboard2";
import PageRoles from "./Pages/Roles/Roles";
import PageMenu from "./Pages/Menu/Menu";
import PageCategories from "./Pages/Categories/Categories";
import PageArticles from "./Pages/Articles";
import PageArticlesList from "./Pages/Articles/Articles";
import PageLogout from "./Pages/Logout";
import PageUsers from "./Pages/Users/Users";
import PageNotifications from "./Pages/Notifications/Notifications";
import PageCompetitions from "./Pages/Competitions/index";
import SignIn from "./Pages/SignIn";
import ErrorPage from "./Pages/ErrorPage";
import Page404 from "./Pages/Page404";
// import PageNotFound from "./Pages/PageNotFound";

if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
}

const App = ({AppStore, ...props}) => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
            window.location = 'https:' + window.location.href.substring(window.location.protocol.length);
        }
        if (window.location.host.startsWith('www.')) {
            window.location = window.location.href.replace('://www.', '://');
        }
        AppStore.setSession();
    }, []);
    // если домен конфига не совпадает с доменом браузера то краш
    return <BrowserRouter basename={process.env.REACT_APP_URL}>
        {AppStore.isError ? <Switch>
            <Route path={'*'} component={ErrorPage} />
        </Switch> : (!AppStore.isSigned ? <Switch>
            <Route path={'/logout'} exact component={PageLogout} />
            <Route path={'*'} exact component={SignIn} />
        </Switch> : <Switch>
            <Route path={'/'} exact component={Dashboard} />
            <Route path={'/logout'} exact component={PageLogout} />
            <Route path={'/users'} exact component={PageUsers} />
            <Route path={'/roles'} exact component={PageRoles} />
            <Route path={'/menu'} exact component={PageMenu} />
            <Route path={'/notifications'} exact component={PageNotifications} />
            <Route path={'/categories'} exact component={PageCategories} />
            <Route path={'/competitions'} component={PageCompetitions} />
            <Route path={'/articles'} component={PageArticles} />
            <Route path={'/:slug'} exact component={PageArticlesList} />
            <Route path={'*'} exact component={Page404} />
            {/*<Route path={'*'} component={PageNotFound} />*/}
        </Switch>) }
    </BrowserRouter>;
}


export default inject('AppStore')(observer(App));

// import React from 'react';
// import './Assets/scss/styles.scss';
// import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
// import {inject, observer} from "mobx-react";
// import Dashboard from "./Pages/Dashboard";
// import PageNotFound from "./Pages/PageNotFound";
// // import Loading from "./Components/Loading";
//
// @inject('AppStore')
// @observer
// class App extends React.Component {
//     componentDidMount() {
//         if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
//             window.location = 'https:' + window.location.href.substring(window.location.protocol.length);
//         }
//         if (window.location.host.startsWith('www.')) {
//             window.location = window.location.href.replace('://www.', '://');
//         }
//     }
//
//     render() {
//         const { AppStore } = this.props;
//         return <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
//             <Switch>
//                 <Route path={'/'} exact component={Dashboard} />
//                 <Route path={'*'} component={PageNotFound} />
//             </Switch>
//         </BrowserRouter>;
//     }
// }
//
// export default App;