import React, {useEffect, useRef, useState} from 'react';
import Container from "../../Components/Container";
import {Redirect} from "react-router-dom";
import {apiGet} from "../../Actions/Api";
import moment from "moment";
import ImgPlay from '../../Assets/img_play.jpg'

const ArticleModify = (props) => {
    const { match } = props
    const { params, url } = match;
    const { id } = params;
    const [headerTitle, setHeaderTitle] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputText, setInputText] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [inputStartAt, setInputStartAt] = useState(new Date());
    const [inputFinishAt, setInputFinishAt] = useState(new Date());
    const [inputContentType, setInputContentType] = useState('texts');
    const [inputSubmissions, setInputSubmissions] = useState([]);
    const [inputMaximumSubmissions, setInputMaximumSubmissions] = useState(0);
    const photoRef = useRef(null);
    const [inputFile, setInputFile] = useState(null);
    const [inputImage, setInputImage] = useState(null);
    const [inputImageUrl, setInputImageUrl] = useState(null);
    const [isLoaded, setLoadingStatus] = useState(false);
    const setData = (obj={}) => {
        const {
            title='',
            image=null,
            text='',
            start_at=new Date() / 1000,
            finish_at=new Date() / 1000,
            content_type='texts',
            maximum_submissions=0
        } = obj;
        setHeaderTitle(title);
        setInputTitle(title);
        setInputText(text);
        setInputStartAt(new Date(start_at));
        setInputFinishAt(new Date(finish_at));
        setInputContentType(content_type);
        setInputMaximumSubmissions(maximum_submissions);
    }
    const load = () => {
        apiGet(`/competitions/${id}/review`)
            .then(({data}) => {
                const {
                    competition, file, submissions
                } = data;
                if(file !== false) {
                    setInputImageUrl(`${file.base_url}/${file.file_name}`);
                }
                setData(competition);
                setInputSubmissions(submissions);
                setLoadingStatus(true);
            });
    }
    useEffect(() => load(), []);

    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {to: '/competitions', label: 'Competitions'},
        {label: headerTitle}
    ];
    const imageUrl = inputFile ? URL.createObjectURL(inputFile) : inputImageUrl;
    return <Container id='competitions' title={headerTitle} breadcrumbs={breadcrumbs} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row mb-2'>
                            <div className='col-4'>
                                { imageUrl !== null && <img src={imageUrl} style={{maxHeight: '200px', maxWidth: '200px', objectFit: 'cover'}} /> }
                            </div>
                            <div className='col-8'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='badge badge-outline-warning me-2'>{moment.unix(inputStartAt).format("MM/DD/YYYY")} - {moment.unix(inputFinishAt).format("MM/DD/YYYY")}</div>
                                        <div className='badge badge-outline-success'>{inputContentType} type</div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12' dangerouslySetInnerHTML={{__html: inputText}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='offset-2 col-8'>
                {inputSubmissions.map((e, i) =>
                <div className='card' key={e.id}>
                    <div className='card-body'>
                        <div className='row mb-2'>
                            <div className='col-2 text-muted'>
                                #{i+1}
                            </div>
                            <div className='col-6 text-center'>
                                by {e.name} <span className='text-muted'>{e.email}</span>
                            </div>
                            <div className='col-4 text-end'>
                                {e.user.cez_uid && <span className='text-muted'>id: {e.user.cez_uid}</span>}
                                <span className='badge badge-outline-success ms-2'>{moment.unix((new Date(e.created_at))).format("MM/DD/YYYY hh:mm:ss")}</span>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-4'>
                                { !!e.image && <a href={e.image} target='_blank' className='me-2'>
                                    <img src={e.image} style={{maxHeight: '200px', maxWidth: '200px', objectFit: 'cover'}} />
                                </a> }
                                { !!e.video && <a href={e.video} target='_blank' className='me-2'>
                                    <img src={ImgPlay} style={{maxHeight: '200px', maxWidth: '200px', objectFit: 'cover'}} />
                                </a> }
                            </div>
                            <div className='col-8'>
                                {e.text.length > 0 && <>
                                    {e.text}
                                </> }
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </Container>;
}


export default ArticleModify;
