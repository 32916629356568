import React, {useEffect, useState} from 'react';
import Container from "../../Components/Container";
import {apiGet, apiPost} from "../../Actions/Api";
import Table from "../../Components/Table";
import {prettySearch} from "../../Actions/replaceDiacritics";
import DeleteModal from "../../Components/DeleteModal";
import {Link, Redirect} from "react-router-dom";
import {inject, observer} from "mobx-react";
import moment from "moment";
import Position from "../../Components/Position";

const Articles = (props) => {
    const {AppStore, match} = props;
    const [responseRedirect, setResponseRedirect] = useState(null);
    const [dataArticles, setDataArticles] = useState([]);
    const [dataFiles, setDataFiles] = useState(new Map());
    const [dataGroups, setDataGroups] = useState(new Map());
    const [isLoaded, setLoadingStatus] = useState(false);
    const [search, setSearch] = useState(false);
    const { params } = match;
    const { slug } = params;
    useEffect(() => load(), []);
    useEffect(() => load(), [props]);
    if(responseRedirect !== null) {
        return <Redirect to={responseRedirect} />;
    }
    const load = () => {
        setLoadingStatus(false);
        const addr = slug  ? `category/${slug}` : '';
        apiGet(`/groups`)
            .then(({data}) => {
                const { groups } = data;
                const temp = new Map();
                for(const x of groups) {
                    temp.set(x.id, x);
                }
                setDataGroups(temp);
            });
        apiGet(`/articles/${addr}`)
            .then(({ data }) => {
                const { articles=[], files=[] } = data;
                const tmp = new Map();
                for(const file of files) {
                    tmp.set(file.id, `${file.base_url}/${file.file_name}`);
                }
                setDataFiles(tmp);
                setDataArticles(articles);
                setLoadingStatus(true);
            })
            .catch(e => {
                const { article=[] } = e;
                if(article.length > 0) {
                    if(article[0] === 'Category not found') {
                        setResponseRedirect('/');
                    }
                }
            });
    };
    const tableRow = ({id, title, image, is_published, published_at, tags=[], position=0, groups=[], reactions=0, views=0}) => {
        const newPublishedAt = moment.unix(published_at).format("MM/DD/YYYY");
        const imageUrl = dataFiles.get(image) || null;
        return <tr key={id}>
            <td>
                {imageUrl !== null && <img src={imageUrl} className="rounded me-3" height={48} /> }
            </td>
            <td>
                <Link to={`/articles/${id}`}>{title}</Link>
            </td>
            <td>{tags.map((e, i) => <span className='badge badge-outline-primary me-1' key={`${id}_tag_${i}`}>{e}</span>)}</td>
            {/*<td>{is_published && published_at < (new Date() / 1000) ? <span className='badge badge-outline-secondary'>Published</span> : <span className='badge badge-outline-primary'>Not published</span>}</td>*/}
            <td>{is_published ? (published_at < (new Date() / 1000) ? <span className='badge badge-outline-secondary'>Zveřejněno </span> : <span className='badge badge-outline-primary'>Čeká na zveřejnění</span>) : <span className='badge badge-outline-primary'>Nezveřejněno </span>}</td>
            <td>
                {groups.length > 0 ? groups.map((groupId) => {
                    const group = dataGroups.get(groupId);
                    if(!group) return null;
                    const { short_name } = group;
                    return <span className='badge badge-outline-secondary me-1' key={`${id}_${groupId}`}>{short_name}</span>
                }) : <span className='badge badge-outline-secondary'>Všem</span>}
            </td>
            <td>{reactions}</td>
            <td>{views}</td>
            <td>{newPublishedAt}</td>
            <td>
                <Link to={`/articles/${id}`} className='action-icon'><i className="mdi mdi-square-edit-outline" /></Link>
                {['a1', 'hlavni'].includes(slug) && <Position
                    path={`articles/${id}/position`}
                    position={position}
                    callback={load}
                /> }
                <DeleteModal
                    title={title}
                    description={`Are you seriously want to delete article "${title}"?`}
                    path={`articles/${id}`}
                    callback={load}
                />
            </td>
        </tr>;
    };
    const breadcrumbs = [
        {to: '/', label: 'Dashboard'},
        {label: 'Articles'}
    ];
    const resultData = dataArticles.filter(({title}, i) => search.length === 0 || prettySearch(search, title));
    const addr = slug ? `/${slug}` : '';
    return <Container id='articles' breadcrumbs={breadcrumbs} withCard={true} isLoaded={isLoaded}>
        <div className='row'>
            <div className='col-12'>
                <Link
                    to={`/articles${addr}/new`}
                    className='btn btn-primary'
                >New</Link>
            </div>
        </div>
        <Table
            columns={[
                {w: 3, title: 'Preview'},
                {title: 'Title'},
                {title: 'Tags'},
                {title: 'Status'},
                {title: 'Dostupnost'},
                {title: 'Likes'},
                {title: 'Views'},
                {title: 'Published at'},
                {w: 2, title: 'Actions'}
            ]}
            entries={resultData}
            onSearch={setSearch}
            Row={tableRow}
        />
    </Container>;
}


export default inject('AppStore')(observer(Articles));
