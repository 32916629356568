import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Logo from '../Assets/images/logo-dark.png';
import Container from "../Components/Container";
import Input from "../Components/Input";
import {apiPost} from "../Actions/Api";
import AppStore from "../Stores/AppStore";


const SignIn = (props) => {
    const [inputEmail, setInputEmail] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputRemember, setInputRemember] = useState(false);
    const [otpIsRequired, setOtpIsRequired] = useState(false);
    const [inputOtp, setInputOtp] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const submit = e => {
        e.preventDefault();
        setFormErrors({});
        apiPost(`/auth`, {
            email: inputEmail,
            password: inputPassword,
            device_hash: AppStore.deviceId,
            trust_device: inputRemember
        })
            .then(({data}) => {
                const { otp_required=false } = data;
                if(otp_required) {
                    setOtpIsRequired(true);
                } else {
                    AppStore.login(data);
                }
            })
            .catch(setFormErrors);
    }
    return <Container auth={true}>
        <div className="auth-brand text-center text-lg-start">
            <Link to='/' className='logo-dark'>
                <span><img src={Logo} alt="" height="50"/></span>
            </Link>
        </div>
        <h4 className="mt-0">Sign In</h4>
        <p className="text-muted mb-4">Enter your email address and password to access account.</p>
        <form onSubmit={submit}>
            { !otpIsRequired ? <>
                <div className="mb-3">
                    <Input
                        title='E-Mail'
                        value={inputEmail}
                        onChange={setInputEmail}
                        errors={['email', formErrors, setFormErrors]}
                    />
                </div>
                <div className="mb-3">
                    <Input
                        title='Password'
                        type='password'
                        value={inputPassword}
                        onChange={setInputPassword}
                        errors={['password', formErrors, setFormErrors]}
                    />
                </div>
                <div className="mb-3">
                    <Input
                        title='Remember me'
                        type='checkbox'
                        value={inputRemember}
                        onChange={setInputRemember}
                    />
                </div>
            </> : <>
                <div className="mb-3">
                    <Input
                        title='Enter One Time Password'
                        value={inputOtp}
                        onChange={setInputOtp}
                    />
                </div>
            </>}

            <div className="d-grid mb-0 text-center">
                <button className="btn btn-primary" type="submit">
                    <i className="mdi mdi-login" /> Log In
                </button>
            </div>
        </form>
    </Container>;
}


export default SignIn;
